/*=================================
=            Variables            =
=================================*/
$salmon: #ff5c5c;
$salmon-hover: #e22626;
$bg-color: #f5f3f1;
$blue: #5284ff;
$blue-dark: #0043e5;

/* Forest green with white added */
$forest-light: #439c43;
$forest: #228b22;	     

$text: #000;
$font: "Roboto", sans-serif;

/*=====================================
=            Custom Styles            =
=====================================*/
html,
body {
  font-family: $font;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased;
}

.hero {

  position: relative;
  padding-top: 1.5em;
  padding-bottom: 2em;
  background-color: $bg-color;


  h1, .content h1 {
    font-weight: 500;
    font-size: 3rem;
    margin-bottom: 0;
  }

  h2, .content h2 {
    font-weight: 400;
    font-size: 2.5rem;
  }

  /* "hero" links are dark */
  a {
      color: #212529;
      text-decoration: none;
  }

  a:hover {
      color: #1dc996;
      text-decoration: none;
  }

  svg.hero-image {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
  }

  svg.hero-left-image {
    left: 0px;
  }
  svg.hero-right-image {
    right: 0px;
  }

  #pi,
  #zeta {
    height: 100%;
  }

  .content {
    position: relative;
    z-index: 1;
  }

  .btn-primary.red {
    background-color: $salmon;
    border-color: $salmon;

    &:hover {
      background-color: $salmon-hover;
      border-color: $salmon-hover;
    }
  }

  .btn-primary.green {
    background-color: $forest-light;
    border-color: $forest-light;

    &:hover {
      background-color: $forest;
      border-color: $forest;
    }
  }

  .btn-primary {
    border-radius: 50px;
    padding: 10px 40px;
    font-size: 19px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

}


/*=======================================
=            Zeta Responsive            =
=======================================*/
@media (max-width:1024px){
  #zeta-bg-color{
    fill: white;
  } 

  #blue-dark-rect, #blue-circle{
    fill: none;
  }
}

@media (max-width:768px){
  
  #zeta{
    display: none;  
  }
}
