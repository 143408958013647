//// This in essence is the styling for the theme the
//// Prooftoys site is using, distinct from styling of proofs.
//// 
//// Except for bootstrap-variables, the imports refer
//// to our themes/hugo-whisper-theme.

// Bootstrap
@import 'bootstrap/functions';
@import 'bootstrap-variables';
@import 'bootstrap/variables';

$hamburger-active-layer-color: $primary;

@import 'bootstrap/mixins';
// @import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/utilities';
@import 'bootstrap/grid';
// @import 'bootstrap/type';
@import 'bootstrap/tables';
// @import "bootstrap/images";
// @import "bootstrap/code";
// @import "bootstrap/forms";
@import "bootstrap/buttons";
// @import "bootstrap/transitions";
// @import "bootstrap/dropdown";
// @import "bootstrap/button-group";
// @import "bootstrap/input-group";
// @import "bootstrap/custom-forms";
// @import "bootstrap/nav";
// @import "bootstrap/navbar";
// @import "bootstrap/card";
// @import "bootstrap/breadcrumb";
// @import "bootstrap/pagination";
// @import "bootstrap/badge";
// @import "bootstrap/jumbotron";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
// @import "bootstrap/media";
// @import "bootstrap/list-group";
// @import "bootstrap/close";
// @import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
// @import "bootstrap/carousel";
// @import "bootstrap/print";

// Libraries
@import 'libraries/hamburgers/hamburgers';
@import 'libraries/pygments/github';


// Components
@import 'components/reset';
@import 'components/type';
@import 'components/page';
@import 'components/header';
@import 'components/footer';
@import 'components/sub-footer';
@import 'components/logo';
@import 'components/main-menu';
@import 'components/main-menu-mobile';
@import 'components/docs-menu';
@import 'components/hamburger';
@import 'components/buttons';
@import 'components/title';
@import 'components/content';
@import 'components/strip';
@import 'components/whitebox';
@import 'components/overview';

@import 'banner';

// Pages
@import 'pages/home';

// Global
body {
  font-family: $font-family-base;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
  color: $black;
}
.container {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}

/* For the site menu. */
div.sidebar {
    /* Manipulated by site.js */
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
}

/* This gets manipulated by site.js */
div.sidebar.displayed {
    display: block;
}

/* Float it at larger sizes on request. */
div.sidebar.floating {
    @include media-breakpoint-up(md) {
        position: fixed;
    }
}

// Display breakpoints for DEV
// body:after {
//   color: #000000;
//   font-size: 12px;
//   padding: 5px;
//   font-weight: bold;
//   right: 10px;
//   position: fixed;
//   text-align: center;
//   text-transform: uppercase;
//   bottom: 10px;
//   width: 200px;
//   z-index: 9999;
//   border: solid 1px #000000;
//   @each $name, $value in $grid-breakpoints {
//     @include media-breakpoint-up($name) {
//       content: "#{$name} - min-width: #{$value}";
//     }
//   }
// }
