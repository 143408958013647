.title {
  font-size: 2.7rem;
  line-height: 1.1;
  font-family: $font-family-base;
  letter-spacing: -0.2px;
  font-weight: 100;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    font-size: 3rem;
    margin-bottom: 30px;
  }
}
.title-summary {
  font-size: 1.6rem;
  line-height: 1.4;
  font-family: $font-family-base;
  letter-spacing: -0.2px;
  font-weight: 200;
  margin-bottom: 10px;
  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }
}
