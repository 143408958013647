.footer {
  background: #eaeaea;
  padding-top: 20px;
  padding-bottom: 20px;
  .footer-inner {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    @include media-breakpoint-up(sm) {
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
    }
  }
  .footer-title {
    color: #ffffff;
    font-size: 1.3rem;
    font-family: $font-family-serif;
    margin-bottom: 10px;
    flex: 0;
    @include media-breakpoint-up(sm) {
      margin: 0;
      flex: 0 0 120px;
    }
  }
  ul.footer-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
    li {
      display: block;
      margin-right: 10px;
      color: #333;
      font-size: 0.9rem;
      line-height: 1.8;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        color: #333;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &.copyright {
        font-weight: bold;
        color: #333;
        display: none;
        @include media-breakpoint-up(md) {
          display: inline-block;
        }
      }
    }
    @include media-breakpoint-up(sm) {
      height: inherit;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      align-items: center;
      justify-content: flex-end;
      li {
        list-style: none;
        a {
          display: inline-block;
          height: 40px;
          padding: 10px 8px 10px 8px;
          font-weight: 300;
        }
      }
    }
  }
}
